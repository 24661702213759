/* eslint-disable */
import { TweenMax, TimelineLite, Power2 } from 'gsap';

class CssSelectorsCheatsheet {
  constructor() {
    this.cheatSheet = document.querySelector('.s-cs-cheatsheet');
    this.circles = [...document.querySelectorAll('.circle')];
    this.switch = document.getElementById('doTheMagic');
  }

  handleClick(e) {
    e.preventDefault();

    const selected = this.getAttribute('data-selected');

    if (selected) {
      const tl = new TimelineLite();

      tl
        .to(this, 0.1, {
          scale: 1.2,
          ease: Power2.easeOut,
          onComplete: () => {
            this.classList.add('selected', 'answered');
          }
        })
        .to(this, 0.2, {
          scale: 1,
          ease: Power2.easeIn
        }, '+=0.1');
    } else {
      TweenMax.fromTo(this, 0.01, {
        x: -3,
      }, {
        x: 3,
        clearProps: 'x',
        repeat:20,
        onStart: () => {
          this.classList.add('wrong');
        }
      });
    }
  }

  handleMouseOut() {
    this.classList.remove('wrong');
  }

  handleSwitch() {
    const selects = [...document.querySelectorAll('[data-selected="true"]')];

    if(this.checked) {
      // reading mode
      selects.map(select => {
        select.classList.add('selected');
      });
    } else {
      // game mode
      selects.map(select => {
        select.classList.contains('answered') || select.classList.remove('selected');
      });
    }
  }

  init() {
    if (this.cheatSheet) {
      this.circles.map(circle => {
        circle.addEventListener('click', this.handleClick);
        circle.addEventListener('mouseout', this.handleMouseOut);
      });

      this.switch.addEventListener('change', this.handleSwitch);
    }
  }
}

export { CssSelectorsCheatsheet };
