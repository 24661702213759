// import { PageTransition } from './components/page-transition';
// Need to import the sass file here for webpack to compile it to CSS
import MicroModal from 'micromodal';
import Vivus from 'vivus';
import MobileDetect from 'mobile-detect';
import { TweenLite } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin'; // eslint-disable-line
import { CssSelectorsCheatsheet } from './components/css-selectors-cheatsheet';
import '../styles/main.scss';

// const pageTransition = new PageTransition();

/**
 * Detect mobile / touch
 */

const md = new MobileDetect(window.navigator.userAgent);
let currentDevice = md.mobile(); // eslint-disable-line
const phones = ['iPhone', 'BlackBerry', 'HTC', 'Nexus', 'Dell', 'Motorola', 'Samsung', 'LG', 'Sony', 'Asus', 'NokiaLumia', 'Micromax', 'Palm', 'Vertu', 'Pantech', 'Fly', 'Wiko', 'iMobile', 'SimValley', 'Wolfgang', 'Alcatel', 'Nintendo', 'Amoi', 'INQ', 'GenericPhone'];
const tablets = ['iPad', 'NexusTablet', 'GoogleTablet', 'SamsungTablet', 'Kindle', 'SurfaceTablet', 'HPTablet', 'AsusTablet', 'BlackBerryTablet', 'HTCtablet', 'MotorolaTablet', 'NookTablet', 'AcerTablet', 'ToshibaTablet', 'LGTablet', 'FujitsuTablet', 'PrestigioTablet', 'LenovoTablet', 'DellTablet', 'YarvikTablet', 'MedionTablet', 'ArnovaTablet', 'IntensoTablet', 'IRUTablet', 'MegafonTablet', 'EbodaTablet', 'AllViewTablet', 'ArchosTablet', 'AinolTablet', 'NokiaLumiaTablet', 'SonyTablet', 'PhilipsTablet', 'CubeTablet', 'CobyTablet', 'MIDTablet', 'MSITablet', 'SMiTTablet', 'RockChipTablet', 'FlyTablet', 'bqTablet', 'HuaweiTablet', 'NecTablet', 'PantechTablet', 'BronchoTablet', 'VersusTablet', 'ZyncTablet', 'PositivoTablet', 'NabiTablet', 'KoboTablet', 'DanewTablet', 'TexetTablet', 'PlaystationTablet', 'TrekstorTablet', 'PyleAudioTablet', 'AdvanTablet', 'DanyTechTablet', 'GalapadTablet', 'MicromaxTablet', 'KarbonnTablet', 'AllFineTablet', 'PROSCANTablet', 'YONESTablet', 'ChangJiaTablet', 'GUTablet', 'PointOfViewTablet', 'OvermaxTablet', 'HCLTablet', 'DPSTablet', 'VistureTablet', 'CrestaTablet', 'MediatekTablet', 'ConcordeTablet', 'GoCleverTablet', 'ModecomTablet', 'VoninoTablet', 'ECSTablet', 'StorexTablet', 'VodafoneTablet', 'EssentielBTablet', 'RossMoorTablet', 'iMobileTablet', 'TolinoTablet', 'AudioSonicTablet', 'AMPETablet', 'SkkTablet', 'TecnoTablet', 'JXDTablet', 'iJoyTablet', 'FX2Tablet', 'XoroTablet', 'ViewsonicTablet', 'VerizonTablet', 'OdysTablet', 'CaptivaTablet', 'IconbitTablet', 'TeclastTablet', 'OndaTablet', 'JaytechTablet', 'BlaupunktTablet', 'DigmaTablet', 'EvolioTablet', 'LavaTablet', 'AocTablet', 'MpmanTablet', 'CelkonTablet', 'WolderTablet', 'MediacomTablet', 'MiTablet', 'NibiruTablet', 'NexoTablet', 'LeaderTablet', 'UbislateTablet', 'PocketBookTablet', 'KocasoTablet', 'HisenseTablet', 'Hudl', 'TelstraTablet', 'GenericTablet'];

let deviceClass = '';
if (phones.includes(currentDevice)) {
  deviceClass = 'phone';
} else if (tablets.includes(currentDevice)) {
  deviceClass = 'tablet';
} else {
  deviceClass = 'desktop';
}

document.querySelector('body').classList.add(deviceClass.toLowerCase());

/**
 * OPTIN :: Detect if input field has any value
 */

const optinInput = document.querySelector('.js-input-val');

if (optinInput) {
  optinInput.addEventListener('blur', (e) => {
    if (optinInput && optinInput.value) {
      e.target.classList.add('is-active');
    } else {
      e.target.classList.remove('is-active');
    }
  });
}

/**
 * Modal
 */

const teasetVideo = document.getElementById('teaser-video');

MicroModal.init({
  awaitCloseAnimation: true,
  onClose: () => {
    if (teasetVideo !== null) {
      teasetVideo.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
    }
  },
});

/**
 * Text animation
 */

const textFill = document.querySelector('.c-text-fill');

const showTextFill = () => {
  textFill.classList.add('is-active');
};

if (textFill !== null) {
  new Vivus('c-text-line', { // eslint-disable-line
    duration: 70,
    animTimingFunction: Vivus.EASE,
  }, showTextFill);
}

// const gif = document.getElementById('super-cool-gif');
// const myImage = new gifsee(gif);

/**
 * Video play
 */

const videoPlayer = document.querySelector('.js-video-player');
const videoContainer = document.querySelector('.c-video--responsive');

if (videoPlayer) {
  videoPlayer.addEventListener('click', function() { // eslint-disable-line
    this.closest('.c-video').classList.add('is-playing');

    const videoFrame = '<iframe src="https://player.vimeo.com/video/297532749?autoplay=1&mute=1&enablejsapi=1&color=FFBE28&title=0&byline=0&portrait=0" allow="autoplay" width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';

    videoContainer.innerHTML = videoFrame;
  });
}

/**
 * Graph
 */

const graphs = [...document.querySelectorAll('.c-course-info__bar-graph')];
const graphTotal = 200;
const graphHighest = 120;

// const totalGraph = graphs
//   .map(graph => Math.floor(graph.getAttribute('data-min')))
//   .reduce((acc, curVal) => acc + curVal, 0);

graphs.forEach((graph) => {
  const graphData = Math.floor(graph.getAttribute('data-min'));
  const graphHeight = Math.floor(graphTotal * graphData / graphHighest);

  graph.setAttribute('style', `height: ${graphHeight}px;`);
});

/**
 * GSAP ScrollTo
 */

const scrollToBtn = document.querySelector('.js-scrollto');

if (scrollToBtn) {
  scrollToBtn.addEventListener('click', function(e) { // eslint-disable-line
    e.preventDefault();

    TweenLite.to(window, 1.5, {
      scrollTo: '#pricing',
      ease: Power4. easeInOut, // eslint-disable-line
    });
  });
}

const cssSelectorsCheatsheet = new CssSelectorsCheatsheet();

cssSelectorsCheatsheet.init();
